export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * JSON encoded string fo metric value e.g.
   * - String list - '["a","b","c"]'
   * - Int list - '[1,2,3,4]'
   * - String - '"my value"'
   * - Int - '10'
   * - Float - '10.34'
   *
   * You can get it by simply calling JSON.stringify(value) in JavaScript.
   */
  ChallengeObjectiveTargetValueJsonString: { input: any; output: any; }
  /**
   * Currency code as string e.g. "USD" or "USDT". We are not using enums for currency codes to set foundation for
   * adding/removing currencies going into the future. This is also the reason why we are not using ISO 4217 currency codes.
   * For possible values see ISO 4217 for fiat currencies and https://coinmarketcap.com/ for crypto currencies.
   */
  CurrencyCode: { input: any; output: any; }
  /** String that is translated to the user's locale based on passed localization header. */
  I18nString: { input: any; output: any; }
  /** Int64 number as sting e.g. "9223372036854775807". */
  Int64Str: { input: any; output: any; }
  /** Number of milliseconds since the epoch (January 1, 1970, UTC) as string e.g. "1698297183747". */
  TimestampStrUtcMilli: { input: any; output: any; }
};

export type Challenge = {
  activeFrom: Scalars['TimestampStrUtcMilli']['output'];
  activeTill: Scalars['TimestampStrUtcMilli']['output'];
  author: Maybe<ChallengeAuthor>;
  completedAt: Scalars['TimestampStrUtcMilli']['output'];
  contributionHistory: Maybe<ChallengeContributionHistory>;
  createdAt: Scalars['TimestampStrUtcMilli']['output'];
  eventsHistory: Maybe<ChallengeGameEventsHistory>;
  executionStatus: ChallengeExecutionStatus;
  game: Game;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  objectives: Array<ChallengeObjective>;
  reward: Maybe<ChallengeRewardPayload>;
  /**
   * Querying streamer data for the challenge causes additional load on the system, use it only if you don't
   * have data about the streamer already.
   */
  streamer: Maybe<Streamer>;
  type: ChallengeType;
  updatedAt: Scalars['TimestampStrUtcMilli']['output'];
};


export type ChallengeContributionHistoryArgs = {
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type ChallengeAuthor = {
  name: Maybe<Scalars['String']['output']>;
};

export enum ChallengeByIdError {
  InternalError = 'INTERNAL_ERROR',
  NotFound = 'NOT_FOUND'
}

export type ChallengeByIdInput = {
  id: Scalars['ID']['input'];
};

export type ChallengeByIdPayload = {
  data: Maybe<Challenge>;
  error: Maybe<ChallengeByIdError>;
};

export type ChallengeContributeData = {
  id: Scalars['ID']['output'];
  web3: Maybe<ChallengeContributeDataWeb3>;
};

export type ChallengeContributeDataWeb3 = {
  createdAt: Scalars['TimestampStrUtcMilli']['output'];
  expiresAt: Scalars['TimestampStrUtcMilli']['output'];
  external: Maybe<ChallengeContributeDataWeb3External>;
  internal: Maybe<ChallengeContributeDataWeb3Internal>;
};

export type ChallengeContributeDataWeb3External = {
  url: Scalars['String']['output'];
};

export type ChallengeContributeDataWeb3Internal = {
  addresses: Array<Web3Address>;
};

export enum ChallengeContributeError {
  /** This means that challenge completed or failed. Either way we're not accepting contributions anymore. */
  ChallengeContributionClosed = 'CHALLENGE_CONTRIBUTION_CLOSED',
  /**
   * There might be a case when payment service doesn't know about the challenge. It might happen due to slow
   * processing either on pub/sub or challenges-worker side. Wait a bit and try again.
   */
  ChallengeDoesNotExist = 'CHALLENGE_DOES_NOT_EXIST',
  InternalError = 'INTERNAL_ERROR',
  InvalidContributorName = 'INVALID_CONTRIBUTOR_NAME',
  InvalidRedirectUrl = 'INVALID_REDIRECT_URL',
  /**
   * This error must be retried. Third-party gateway has rate-limit restriction, if we hit it, there is no other way
   * except to wait a bit and try again.
   */
  ThirdPartyRateLimit = 'THIRD_PARTY_RATE_LIMIT'
}

export type ChallengeContributeInput = {
  /** cancelRedirectUrl must contain {{contribution_id}} that will be replaced to actual Contribution ID. */
  cancelRedirectUrl: Scalars['String']['input'];
  challengeId: Scalars['ID']['input'];
  /** Contributor name is optional, it is completely fine to go anonymously. */
  contributorName?: InputMaybe<Scalars['String']['input']>;
  /** Defines whether to use mock payment provider instead of real one, INTERNAL_ERROR will be returned if mocking is disabled. */
  mock?: InputMaybe<Scalars['Boolean']['input']>;
  /** successRedirectUrl must contain {{contribution_id}} that will be replaced to actual Contribution ID. */
  successRedirectUrl: Scalars['String']['input'];
};

export type ChallengeContributePayload = {
  data: Maybe<ChallengeContributeData>;
  error: Maybe<ChallengeContributeError>;
  result: Scalars['Boolean']['output'];
};

export type ChallengeContribution = {
  id: Scalars['ID']['output'];
  status: ContributionStatus;
};

export type ChallengeContributionAuthor = {
  name: Maybe<Scalars['String']['output']>;
};

export type ChallengeContributionByIdInput = {
  id: Scalars['ID']['input'];
};

export type ChallengeContributionHistory = {
  data: Maybe<Array<ChallengeContributionHistoryItem>>;
  error: Maybe<ChallengeContributionHistoryError>;
  pageInfo: Maybe<PageInfo>;
};

export enum ChallengeContributionHistoryError {
  InternalError = 'INTERNAL_ERROR',
  InvalidPaginationCursor = 'INVALID_PAGINATION_CURSOR',
  InvalidPaginationDirection = 'INVALID_PAGINATION_DIRECTION',
  InvalidPaginationLimit = 'INVALID_PAGINATION_LIMIT'
}

export type ChallengeContributionHistoryItem = {
  approxValue: CurrencyValue;
  author: Maybe<ChallengeContributionAuthor>;
  contributionId: Scalars['String']['output'];
  date: Scalars['TimestampStrUtcMilli']['output'];
  /** TransactionId is used as id because it is technically possible to have two transactions for the same contribution id. */
  id: Scalars['ID']['output'];
  value: CurrencyValue;
};

export enum ChallengeError {
  InternalError = 'INTERNAL_ERROR',
  NotFound = 'NOT_FOUND'
}

export enum ChallengeExecutionStatus {
  ActivationRequired = 'ACTIVATION_REQUIRED',
  Active = 'ACTIVE',
  Complete = 'COMPLETE',
  Failed = 'FAILED'
}

export type ChallengeGameEventsHistory = {
  data: Maybe<Array<ChallengeGameEventsHistoryEvent>>;
  error: Maybe<ChallengeGameEventsHistoryError>;
};

export enum ChallengeGameEventsHistoryError {
  InternalError = 'INTERNAL_ERROR'
}

export type ChallengeGameEventsHistoryEvent = {
  challengeResult: Maybe<Array<ChallengeGameEventsHistoryMetric>>;
  gameEventFinishedAt: Scalars['TimestampStrUtcMilli']['output'];
  gameEventId: Scalars['String']['output'];
  gameEventStartedAt: Scalars['TimestampStrUtcMilli']['output'];
  gameEventType: GameEventType;
  id: Scalars['ID']['output'];
};

/**
 * ChallengeGameEventsHistoryMetric doesn't have an id because it make sense only
 * as part of a ChallengeGameEventsHistoryEvent
 */
export type ChallengeGameEventsHistoryMetric = {
  result: ChallengeGameEventsHistoryMetricExecResult;
  resultValue: Maybe<ChallengeObjectiveTargetValue>;
  target: ChallengeObjectiveTarget;
};

export enum ChallengeGameEventsHistoryMetricExecResult {
  Complete = 'COMPLETE',
  Failed = 'FAILED'
}

export enum ChallengeHistoricalProjectionError {
  InternalError = 'INTERNAL_ERROR',
  InvalidChallengeType = 'INVALID_CHALLENGE_TYPE',
  InvalidGameId = 'INVALID_GAME_ID',
  InvalidObjectives = 'INVALID_OBJECTIVES',
  InvalidStreamerId = 'INVALID_STREAMER_ID'
}

export type ChallengeHistoricalProjectionInput = {
  gameId: GameId;
  objectives: Array<ChallengeObjectiveInput>;
  streamerId: Scalars['ID']['input'];
  type: ChallengeType;
};

export type ChallengeHistoricalProjectionPayload = {
  data: Maybe<Array<ChallengeGameEventsHistoryEvent>>;
  error: Maybe<ChallengeHistoricalProjectionError>;
};

export type ChallengeMetric = ChallengeMetricFloat | ChallengeMetricInt | ChallengeMetricStr | ChallengeMetricStrList;

export type ChallengeMetricBase = {
  compOperators: Array<CompOperator>;
  description: Scalars['I18nString']['output'];
  game: Game;
  id: Scalars['ID']['output'];
  name: Scalars['I18nString']['output'];
  type: ChallengeMetricType;
};

export type ChallengeMetricFilterInput = {
  gameId?: InputMaybe<Array<GameId>>;
};

export type ChallengeMetricFloat = ChallengeMetricBase & {
  compOperators: Array<CompOperator>;
  description: Scalars['I18nString']['output'];
  displayFormat: Scalars['String']['output'];
  game: Game;
  id: Scalars['ID']['output'];
  maxTargetValue: Maybe<Scalars['Float']['output']>;
  minTargetValue: Maybe<Scalars['Float']['output']>;
  name: Scalars['I18nString']['output'];
  type: ChallengeMetricType;
};

export type ChallengeMetricInt = ChallengeMetricBase & {
  compOperators: Array<CompOperator>;
  description: Scalars['I18nString']['output'];
  displayFormat: Scalars['String']['output'];
  game: Game;
  id: Scalars['ID']['output'];
  maxTargetValue: Maybe<Scalars['Int']['output']>;
  minTargetValue: Maybe<Scalars['Int']['output']>;
  name: Scalars['I18nString']['output'];
  type: ChallengeMetricType;
};

export type ChallengeMetricPayload = {
  data: Maybe<Array<ChallengeMetric>>;
  error: Maybe<ChallengeMetricPayloadError>;
};

export enum ChallengeMetricPayloadError {
  InternalError = 'INTERNAL_ERROR',
  InvalidGame = 'INVALID_GAME'
}

export type ChallengeMetricStr = ChallengeMetricBase & {
  allowedValues: Array<ChallengeMetricStrAllowedItem>;
  compOperators: Array<CompOperator>;
  description: Scalars['I18nString']['output'];
  game: Game;
  id: Scalars['ID']['output'];
  name: Scalars['I18nString']['output'];
  type: ChallengeMetricType;
};

export type ChallengeMetricStrAllowedItem = {
  iconUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Maybe<Scalars['I18nString']['output']>;
};

export type ChallengeMetricStrList = ChallengeMetricBase & {
  allowedValues: Array<ChallengeMetricStrListAllowedItem>;
  compOperators: Array<CompOperator>;
  description: Scalars['I18nString']['output'];
  game: Game;
  id: Scalars['ID']['output'];
  maxItems: Maybe<Scalars['Int']['output']>;
  minItems: Maybe<Scalars['Int']['output']>;
  name: Scalars['I18nString']['output'];
  type: ChallengeMetricType;
};

export type ChallengeMetricStrListAllowedItem = {
  iconUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Maybe<Scalars['I18nString']['output']>;
};

export enum ChallengeMetricType {
  AggregatableTillDate = 'AGGREGATABLE_TILL_DATE',
  OneGame = 'ONE_GAME'
}

export enum ChallengeNameError {
  InternalError = 'INTERNAL_ERROR'
}

export type ChallengeNameInput = {
  /** Game Id defines list of base works to make a name of challenge. */
  gameId: GameId;
};

export type ChallengeNamePayload = {
  error: Maybe<ChallengeNameError>;
  name: Maybe<Scalars['String']['output']>;
};

/** ChallengeObjective doesn't have a ID, because it makes sense only in the context of the Challenge. */
export type ChallengeObjective = {
  currentValue: Maybe<ChallengeObjectiveTargetValue>;
  target: ChallengeObjectiveTarget;
};

export type ChallengeObjectiveInput = {
  compOperator: CompOperatorKind;
  metricId: Scalars['String']['input'];
  targetValue: Scalars['ChallengeObjectiveTargetValueJsonString']['input'];
};

export type ChallengeObjectiveTarget = {
  compOperator: CompOperator;
  metric: ChallengeMetric;
  targetValue: ChallengeObjectiveTargetValue;
};

export type ChallengeObjectiveTargetValue = ChallengeObjectiveTargetValueFloat | ChallengeObjectiveTargetValueInt | ChallengeObjectiveTargetValueIntList | ChallengeObjectiveTargetValueStr | ChallengeObjectiveTargetValueStrList;

export type ChallengeObjectiveTargetValueFloat = {
  displayFormat: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type ChallengeObjectiveTargetValueInt = {
  displayFormat: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type ChallengeObjectiveTargetValueIntList = {
  data: Array<ChallengeObjectiveTargetValueIntListItem>;
};

export type ChallengeObjectiveTargetValueIntListItem = {
  iconUrl: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['I18nString']['output']>;
  value: Scalars['Int']['output'];
};

export type ChallengeObjectiveTargetValueStr = {
  iconUrl: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['I18nString']['output']>;
  value: Scalars['String']['output'];
};

export type ChallengeObjectiveTargetValueStrList = {
  data: Array<ChallengeObjectiveTargetValueStrListItem>;
};

export type ChallengeObjectiveTargetValueStrListItem = {
  iconUrl: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['I18nString']['output']>;
  value: Scalars['String']['output'];
};

export type ChallengePayload = {
  data: Maybe<Challenge>;
  error: Maybe<ChallengeError>;
};

export enum ChallengePostMutationError {
  InternalError = 'INTERNAL_ERROR'
}

export type ChallengePostMutationPayload = {
  data: Maybe<Challenge>;
  error: Maybe<ChallengePostMutationError>;
};

export type ChallengePreset = {
  activeFrom: Maybe<Scalars['TimestampStrUtcMilli']['output']>;
  activeTill: Maybe<Scalars['TimestampStrUtcMilli']['output']>;
  game: Game;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  objectiveTargets: Array<ChallengeObjectiveTarget>;
  type: ChallengeType;
};

export enum ChallengePresetByIdError {
  InternalError = 'INTERNAL_ERROR',
  InvalidId = 'INVALID_ID',
  NotFound = 'NOT_FOUND'
}

export type ChallengePresetByIdInput = {
  id: Scalars['ID']['input'];
};

export type ChallengePresetByIdPayload = {
  data: Maybe<ChallengePreset>;
  error: Maybe<ChallengePresetByIdError>;
};

export type ChallengeRewardData = {
  approxReward: CurrencyValue;
  balances: Array<CurrencyValue>;
};

export enum ChallengeRewardError {
  InternalError = 'INTERNAL_ERROR'
}

export type ChallengeRewardPayload = {
  data: Maybe<ChallengeRewardData>;
  error: Maybe<ChallengeRewardError>;
};

export type ChallengeSubmissionData = {
  challenge: ChallengePostMutationPayload;
  streamer: StreamerPostMutationPayload;
};

export enum ChallengeSubmissionError {
  InternalError = 'INTERNAL_ERROR',
  InvalidActiveFrom = 'INVALID_ACTIVE_FROM',
  InvalidActiveTill = 'INVALID_ACTIVE_TILL',
  InvalidAuthorName = 'INVALID_AUTHOR_NAME',
  InvalidChallengeName = 'INVALID_CHALLENGE_NAME',
  InvalidObjectives = 'INVALID_OBJECTIVES',
  InvalidStreamerId = 'INVALID_STREAMER_ID',
  InvalidType = 'INVALID_TYPE',
  StreamerDoesNotExist = 'STREAMER_DOES_NOT_EXIST',
  StreamerDoesNotHaveConnectedGame = 'STREAMER_DOES_NOT_HAVE_CONNECTED_GAME'
}

export type ChallengeSubmissionInput = {
  /** Number of milliseconds since the epoch (January 1, 1970, UTC) as string e.g. "1698297183747". */
  activeFrom?: InputMaybe<Scalars['TimestampStrUtcMilli']['input']>;
  /** Number of milliseconds since the epoch (January 1, 1970, UTC) as string e.g. "1698297183747". */
  activeTill?: InputMaybe<Scalars['TimestampStrUtcMilli']['input']>;
  /** You can leave authorName empty to keep anonymously. If specified, it must be between 2 and 100 symbols. */
  authorName?: InputMaybe<Scalars['String']['input']>;
  gameId: GameId;
  /** Challenge name should be at least 2 symbols long and no more that 255 symbols. */
  name: Scalars['String']['input'];
  objectives: Array<ChallengeObjectiveInput>;
  streamerId: Scalars['ID']['input'];
  type: ChallengeType;
};

export type ChallengeSubmissionPayload = {
  data: Maybe<ChallengeSubmissionData>;
  error: Maybe<ChallengeSubmissionError>;
  errorMessage: Maybe<Scalars['I18nString']['output']>;
  result: Scalars['Boolean']['output'];
};

export enum ChallengeType {
  /**
   * In order to complete "AGGREGATABLE_TILL_DATE" challenge, one must fulfill objectives of the challenge
   * till "activeTill" date.
   */
  AggregatableTillDate = 'AGGREGATABLE_TILL_DATE',
  /** In order to complete "ONE_GAME" challenge, one must complete all the objectives of the challenge during single game. */
  OneGame = 'ONE_GAME'
}

export type ChallengesFeaturedData = {
  challenges: Array<Challenge>;
};

export enum ChallengesFeaturedError {
  InternalError = 'INTERNAL_ERROR'
}

export type ChallengesFeaturedPayload = {
  data: Maybe<ChallengesFeaturedData>;
  error: Maybe<ChallengesFeaturedError>;
};

export type CompOperator = {
  description: Scalars['I18nString']['output'];
  kind: CompOperatorKind;
  name: Scalars['I18nString']['output'];
};

export enum CompOperatorKind {
  Eq = 'EQ',
  Greater = 'GREATER',
  GreaterOrEq = 'GREATER_OR_EQ',
  Includes = 'INCLUDES',
  Less = 'LESS',
  LessOrEq = 'LESS_OR_EQ',
  NotEq = 'NOT_EQ',
  NotIncludes = 'NOT_INCLUDES'
}

export enum ContributionByIdError {
  InternalError = 'INTERNAL_ERROR',
  NotFound = 'NOT_FOUND'
}

export type ContributionByIdPayload = {
  data: Maybe<ChallengeContribution>;
  error: Maybe<ContributionByIdError>;
};

export enum ContributionStatus {
  Complete = 'COMPLETE',
  Pending = 'PENDING'
}

export type Currency = FiatCurrency | Web3Currency;

export type CurrencyValue = FiatCurrencyValue | Web3CurrencyValue;

export type CurrencyValueInput = {
  currencyId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type CursorPaginationInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  direction: ListDirection;
  limit: Scalars['Int']['input'];
};

export type FiatCurrency = {
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxWithdrawalAmount: Scalars['String']['output'];
  minWithdrawalAmount: Scalars['String']['output'];
};

export type FiatCurrencyValue = {
  currency: FiatCurrency;
  value: Scalars['String']['output'];
};

export type Game = {
  bgImageUrl: Scalars['String']['output'];
  fullName: Scalars['I18nString']['output'];
  iconUrl: Scalars['String']['output'];
  id: GameId;
  shortName: Scalars['I18nString']['output'];
};

export enum GameEventType {
  LolHistoricalGame = 'LolHistoricalGame',
  TftHistoricalGame = 'TftHistoricalGame'
}

export enum GameId {
  Apex = 'APEX',
  Csgo = 'CSGO',
  Dota2 = 'DOTA2',
  Lol = 'LOL',
  Tft = 'TFT',
  Val = 'VAL'
}

export type GameInfo = {
  game: Game;
  isSupported: Scalars['Boolean']['output'];
};

export enum GamesInfoError {
  InternalError = 'INTERNAL_ERROR'
}

export type GamesInfoPayload = {
  data: Maybe<Array<GameInfo>>;
  error: Maybe<GamesInfoError>;
};

export type I18nInfo = {
  desc: Scalars['String']['output'];
  isSupported: Scalars['Boolean']['output'];
  slug: I18nSlug;
};

export type I18nPayload = {
  data: Maybe<Array<I18nInfo>>;
  error: Maybe<I18nPayloadError>;
};

export enum I18nPayloadError {
  InternalError = 'INTERNAL_ERROR'
}

export enum I18nSlug {
  /** Czech (Czech Republic) */
  CsCz = 'CS_CZ',
  /** German (Germany) */
  DeDe = 'DE_DE',
  /** Greek (Greece) */
  ElGr = 'EL_GR',
  /** English (Australia) */
  EnAu = 'EN_AU',
  /** English (United Kingdom) */
  EnGb = 'EN_GB',
  /** English (Republic of the Philippines) */
  EnPh = 'EN_PH',
  /** English (Singapore) */
  EnSg = 'EN_SG',
  /** English (United States) */
  EnUs = 'EN_US',
  /** Spanish (Argentina) */
  EsAr = 'ES_AR',
  /** Spanish (Spain) */
  EsEs = 'ES_ES',
  /** Spanish (Mexico) */
  EsMx = 'ES_MX',
  /** French (France) */
  FrFr = 'FR_FR',
  /** Hungarian (Hungary) */
  HuHu = 'HU_HU',
  /** Indonesian (Indonesia) */
  IdId = 'ID_ID',
  /** Italian (Italy) */
  ItIt = 'IT_IT',
  /** Japanese (Japan) */
  JaJp = 'JA_JP',
  /** Korean (Korea) */
  KoKr = 'KO_KR',
  /** Malay (Malaysia) */
  MsMy = 'MS_MY',
  /** Polish (Poland) */
  PlPl = 'PL_PL',
  /** Portuguese (Brazil) */
  PtBr = 'PT_BR',
  /** Romanian (Romania) */
  RoRo = 'RO_RO',
  /** Russian (Russia) */
  RuRu = 'RU_RU',
  /** Thai (Thailand) */
  ThTh = 'TH_TH',
  /** Turkish (Turkey) */
  TrTr = 'TR_TR',
  /** Vietnamese (Viet Nam) */
  VnVn = 'VN_VN',
  /** Chinese (China) */
  ZhCn = 'ZH_CN',
  /** Chinese (Malaysia) */
  ZhMy = 'ZH_MY',
  /** Chinese (Taiwan) */
  ZhTw = 'ZH_TW'
}

export enum ListDirection {
  Backward = 'BACKWARD',
  Forward = 'FORWARD'
}

export type Mutation = {
  challengeContribute: ChallengeContributePayload;
  challengeSubmit: ChallengeSubmissionPayload;
  riotAccountConnect: RiotAccountConnectPayload;
  riotAccountDisconnect: RiotAccountDisconnectPayload;
  riotAccountDiscover: RiotAccountDiscoverPayload;
  riotAccountRefresh: RiotAccountRefreshPayload;
  streamerRegister: StreamerRegisterPayload;
  streamerUpdateWeb3Wallet: StreamerUpdateWeb3WalletPayload;
  streamerWithdraw: StreamerBalanceWithdrawPayload;
  userMarketingSettingsUpdate: UserMarketingSettingsUpdatePayload;
};


export type MutationChallengeContributeArgs = {
  input: ChallengeContributeInput;
};


export type MutationChallengeSubmitArgs = {
  input: ChallengeSubmissionInput;
};


export type MutationRiotAccountConnectArgs = {
  input: RiotAccountConnectInput;
};


export type MutationRiotAccountDisconnectArgs = {
  input: RiotAccountDisconnectInput;
};


export type MutationRiotAccountDiscoverArgs = {
  input: RiotAccountDiscoverInput;
};


export type MutationRiotAccountRefreshArgs = {
  input: RiotAccountRefreshInput;
};


export type MutationStreamerUpdateWeb3WalletArgs = {
  input: StreamerUpdateWeb3WalletInput;
};


export type MutationStreamerWithdrawArgs = {
  input: StreamerBalanceWithdrawInput;
};


export type MutationUserMarketingSettingsUpdateArgs = {
  input: UserMarketingSettingsUpdateInput;
};

export type PageInfo = {
  nextCursor: Maybe<Scalars['String']['output']>;
  prevCursor: Maybe<Scalars['String']['output']>;
};

export type Query = {
  _twitchApiStub: Maybe<Scalars['String']['output']>;
  challengeById: ChallengeByIdPayload;
  challengeContributionById: ContributionByIdPayload;
  challengeHistoricalProjection: ChallengeHistoricalProjectionPayload;
  challengeName: ChallengeNamePayload;
  challengePresetById: ChallengePresetByIdPayload;
  /** Challenges that has the most number of transactions in the last X days. */
  challengesFeatured: ChallengesFeaturedPayload;
  challengesMetrics: ChallengeMetricPayload;
  gamesInfo: GamesInfoPayload;
  i18n: I18nPayload;
  streamerById: StreamerByIdPayload;
  streamerSearch: StreamerSearchPayload;
  /** Streamers who have the most number of transactions in the last X days. */
  streamersFeatured: StreamersFeaturedPayload;
  user: UserPayload;
};


export type QueryChallengeByIdArgs = {
  input: ChallengeByIdInput;
};


export type QueryChallengeContributionByIdArgs = {
  input: ChallengeContributionByIdInput;
};


export type QueryChallengeHistoricalProjectionArgs = {
  input: ChallengeHistoricalProjectionInput;
};


export type QueryChallengeNameArgs = {
  input: ChallengeNameInput;
};


export type QueryChallengePresetByIdArgs = {
  input: ChallengePresetByIdInput;
};


export type QueryChallengesMetricsArgs = {
  filter: ChallengeMetricFilterInput;
};


export type QueryStreamerByIdArgs = {
  input: StreamerByIdInput;
};


export type QueryStreamerSearchArgs = {
  input: StreamerSearchInput;
};

export type RiotAccountConnectData = {
  streamer: StreamerPostMutationPayload;
};

export enum RiotAccountConnectError {
  /** Riot account is already connected to this or another streamer. */
  AlreadyExists = 'ALREADY_EXISTS',
  /**
   * Riot account is already connected to this or another streamer under another Riot ID. During connection attempt, Riot account was refreshed.
   * Updated Riot Account can be obtained from RiotAccountConnectPayload.data field.
   */
  AlreadyExistsAndRefreshed = 'ALREADY_EXISTS_AND_REFRESHED',
  InternalError = 'INTERNAL_ERROR',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND',
  TooManyAccounts = 'TOO_MANY_ACCOUNTS'
}

export type RiotAccountConnectInput = {
  discoveryId: Scalars['String']['input'];
};

export type RiotAccountConnectPayload = {
  data: Maybe<RiotAccountConnectData>;
  error: Maybe<RiotAccountConnectError>;
  result: Scalars['Boolean']['output'];
};

export type RiotAccountData = {
  createdAt: Scalars['String']['output'];
  /** Part of RiotID that comes before the # e.g. noonewins (Full RiotID would be noonewins#3323). */
  gameName: Scalars['String']['output'];
  /** Riot games connected by this account. If game is absent in this least, it means we will not be loading game events for it. */
  games: Maybe<Array<Game>>;
  /** Our internal ID for the RiotAccount e.g. 4cc4d5c2-2d33-4656-920d-ec171619eb6a. */
  id: Scalars['ID']['output'];
  /** Status of the account. */
  status: RiotAccountStatus;
  /** Part of RiotID that comes after the # e.g. 3323 (Full RiotID would be noonewins#3323). */
  tagLine: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type RiotAccountDisconnectData = {
  streamer: StreamerPostMutationPayload;
};

export enum RiotAccountDisconnectError {
  InternalError = 'INTERNAL_ERROR',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND'
}

export type RiotAccountDisconnectInput = {
  accountId: Scalars['String']['input'];
};

export type RiotAccountDisconnectPayload = {
  data: Maybe<RiotAccountDisconnectData>;
  error: Maybe<RiotAccountDisconnectError>;
  result: Scalars['Boolean']['output'];
};

export type RiotAccountDiscoverData = {
  gameName: Scalars['String']['output'];
  games: Maybe<Array<RiotAccountDiscoverDataGame>>;
  id: Scalars['ID']['output'];
  tagLine: Scalars['String']['output'];
};

export type RiotAccountDiscoverDataGame = {
  game: Game;
  lastActiveAt: Scalars['TimestampStrUtcMilli']['output'];
};

export enum RiotAccountDiscoverError {
  AlreadyExists = 'ALREADY_EXISTS',
  InternalError = 'INTERNAL_ERROR',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND',
  NoSupportedGamesPlayedByAccount = 'NO_SUPPORTED_GAMES_PLAYED_BY_ACCOUNT',
  RiotApiUnavailable = 'RIOT_API_UNAVAILABLE',
  RiotGameNameInvalid = 'RIOT_GAME_NAME_INVALID',
  RiotTagLineInvalid = 'RIOT_TAG_LINE_INVALID',
  TooManyAccounts = 'TOO_MANY_ACCOUNTS',
  TooManyDiscoveries = 'TOO_MANY_DISCOVERIES'
}

export type RiotAccountDiscoverInput = {
  /** Example: noonewins */
  gameName: Scalars['String']['input'];
  /** Example: 3342 */
  tagLine: Scalars['String']['input'];
};

export type RiotAccountDiscoverPayload = {
  data: Maybe<RiotAccountDiscoverData>;
  error: Maybe<RiotAccountDiscoverError>;
  result: Scalars['Boolean']['output'];
};

export type RiotAccountRefreshData = {
  streamer: StreamerPostMutationPayload;
};

export enum RiotAccountRefreshError {
  InternalError = 'INTERNAL_ERROR',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND',
  RiotApiUnavailable = 'RIOT_API_UNAVAILABLE',
  TooManyAccountRefreshAttempts = 'TOO_MANY_ACCOUNT_REFRESH_ATTEMPTS'
}

export type RiotAccountRefreshInput = {
  accountId: Scalars['String']['input'];
};

export type RiotAccountRefreshPayload = {
  data: Maybe<RiotAccountRefreshData>;
  error: Maybe<RiotAccountRefreshError>;
  result: Scalars['Boolean']['output'];
};

export enum RiotAccountStatus {
  /** Connected means that account is fine and fully operational. We will be loading game events for this account. */
  Connected = 'CONNECTED',
  /** Suspended means that account stopped playing games we support. We won't be loading any new game events for this account. */
  Suspended = 'SUSPENDED'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Streamer = {
  balance: StreamerBalancePayload;
  challenges: Maybe<StreamerChallengesPayload>;
  challengesFeatured: StreamerChallengesFeaturedPayload;
  challengesStats: Maybe<StreamerChallengesStatsPayload>;
  games: Maybe<StreamerGamesPayload>;
  iconUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  onboarding: StreamerOnboardingPayload;
  riotAccounts: StreamerRiotAccountsPayload;
  streamingPlatforms: Maybe<StreamingPlatformsPayload>;
  web3Wallets: StreamerWeb3WalletsPayload;
  withdrawHistory: StreamerWithdrawHistoryPayload;
};


export type StreamerChallengesArgs = {
  filter?: InputMaybe<StreamerChallengesFilterInput>;
  pagination?: InputMaybe<CursorPaginationInput>;
  sortBy?: InputMaybe<StreamerChallengesSortByInput>;
};


export type StreamerWithdrawHistoryArgs = {
  pagination?: InputMaybe<CursorPaginationInput>;
};

export type StreamerBalance = {
  status: StreamerBalanceStatus;
  value: CurrencyValue;
};

export type StreamerBalanceData = {
  approxBalances: Maybe<Array<StreamerBalance>>;
  approxWithdrawals: Maybe<Array<StreamerWithdrawal>>;
  balances: Maybe<Array<StreamerBalance>>;
  withdrawals: Maybe<Array<StreamerWithdrawal>>;
};

export enum StreamerBalanceError {
  InternalError = 'INTERNAL_ERROR',
  IsNotStreamer = 'IS_NOT_STREAMER',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type StreamerBalancePayload = {
  data: Maybe<StreamerBalanceData>;
  error: Maybe<StreamerBalanceError>;
};

export enum StreamerBalanceStatus {
  /** Funds attached to complete challenges. Available for withdrawal. */
  Available = 'AVAILABLE',
  /** Funds attached to failed challenges. Not available for withdrawal. */
  OnHold = 'ON_HOLD',
  /** Funds attached to active challenges count as pending. Not available for withdrawal. */
  Pending = 'PENDING',
  /** Funds attached to inactive challenges. Not available for withdrawal. */
  Processing = 'PROCESSING'
}

export type StreamerBalanceWithdrawData = {
  streamer: StreamerPostMutationPayload;
  withdrawalId: Scalars['ID']['output'];
};

export enum StreamerBalanceWithdrawError {
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  InternalError = 'INTERNAL_ERROR',
  InvalidAmount = 'INVALID_AMOUNT',
  InvalidCurrency = 'INVALID_CURRENCY',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type StreamerBalanceWithdrawInput = {
  amount: Scalars['String']['input'];
  currencyId: Scalars['ID']['input'];
};

export type StreamerBalanceWithdrawPayload = {
  data: Maybe<StreamerBalanceWithdrawData>;
  error: Maybe<StreamerBalanceWithdrawError>;
  result: Scalars['Boolean']['output'];
};

export enum StreamerByIdError {
  InternalError = 'INTERNAL_ERROR',
  InvalidStreamerId = 'INVALID_STREAMER_ID',
  NotFound = 'NOT_FOUND'
}

export type StreamerByIdInput = {
  id: Scalars['ID']['input'];
};

export type StreamerByIdPayload = {
  data: Maybe<Streamer>;
  error: Maybe<StreamerByIdError>;
};

export enum StreamerChallengesError {
  InternalError = 'INTERNAL_ERROR',
  InvalidExecutionStatusFilter = 'INVALID_EXECUTION_STATUS_FILTER',
  InvalidGameFilter = 'INVALID_GAME_FILTER',
  InvalidListDirection = 'INVALID_LIST_DIRECTION',
  InvalidNameFilter = 'INVALID_NAME_FILTER',
  InvalidPaginationCursor = 'INVALID_PAGINATION_CURSOR',
  InvalidPaginationLimit = 'INVALID_PAGINATION_LIMIT',
  InvalidSortDirection = 'INVALID_SORT_DIRECTION',
  InvalidSortField = 'INVALID_SORT_FIELD'
}

export type StreamerChallengesFeaturedData = {
  challenges: Array<Challenge>;
};

export enum StreamerChallengesFeaturedError {
  InternalError = 'INTERNAL_ERROR'
}

export type StreamerChallengesFeaturedPayload = {
  data: Maybe<StreamerChallengesFeaturedData>;
  error: Maybe<StreamerChallengesFeaturedError>;
};

export enum StreamerChallengesFilterGame {
  All = 'ALL',
  Lol = 'LOL',
  Tft = 'TFT'
}

export type StreamerChallengesFilterInput = {
  /** Empty or unspecified value means All */
  executionStatus?: InputMaybe<Array<ChallengeExecutionStatus>>;
  game?: InputMaybe<StreamerChallengesFilterGame>;
  /** Name should be at least 3 symbols long and no more that 10 symbols. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type StreamerChallengesPayload = {
  data: Maybe<Array<Challenge>>;
  error: Maybe<StreamerChallengesError>;
  pageInfo: Maybe<PageInfo>;
};

export type StreamerChallengesSortByInput = {
  direction: SortDirection;
  field: StreamerChallengesSortField;
};

export enum StreamerChallengesSortField {
  CreatedAt = 'CREATED_AT'
}

export type StreamerChallengesStats = {
  byExecutionStats: Maybe<Array<Maybe<StreamerChallengesStatsByExecutionStats>>>;
};

export type StreamerChallengesStatsByExecutionStats = {
  count: Scalars['Int']['output'];
  status: ChallengeExecutionStatus;
};

export enum StreamerChallengesStatsError {
  InternalError = 'INTERNAL_ERROR'
}

export type StreamerChallengesStatsPayload = {
  data: Maybe<StreamerChallengesStats>;
  error: Maybe<StreamerChallengesStatsError>;
};

export type StreamerGame = {
  challengePresets: Maybe<Array<ChallengePreset>>;
  game: Maybe<Game>;
};

export enum StreamerGamesError {
  InternalError = 'INTERNAL_ERROR'
}

export type StreamerGamesPayload = {
  data: Maybe<Array<StreamerGame>>;
  error: Maybe<StreamerGamesError>;
};

export type StreamerOnboarding = {
  /** Onboarding complete if streamer has at least one connected game and withdrawal method set. */
  steps: Array<StreamerOnboardingStep>;
};

export enum StreamerOnboardingError {
  InternalError = 'INTERNAL_ERROR'
}

export type StreamerOnboardingPayload = {
  data: Maybe<StreamerOnboarding>;
  error: Maybe<StreamerOnboardingError>;
};

export type StreamerOnboardingStep = {
  id: StreamerOnboardingStepId;
  status: StreamerOnboardingStepStatus;
};

export enum StreamerOnboardingStepId {
  Game = 'GAME'
}

export enum StreamerOnboardingStepStatus {
  Done = 'DONE',
  Required = 'REQUIRED'
}

export enum StreamerPostMutationError {
  InternalError = 'INTERNAL_ERROR'
}

export type StreamerPostMutationPayload = {
  data: Maybe<Streamer>;
  error: Maybe<StreamerPostMutationError>;
};

export type StreamerRegisterData = {
  streamer: StreamerPostMutationPayload;
};

export enum StreamerRegisterError {
  AlreadyStreamer = 'ALREADY_STREAMER',
  InternalError = 'INTERNAL_ERROR',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type StreamerRegisterPayload = {
  data: Maybe<StreamerRegisterData>;
  error: Maybe<StreamerRegisterError>;
  result: Scalars['Boolean']['output'];
};

export type StreamerRiotAccountsData = {
  accounts: Maybe<Array<RiotAccountData>>;
};

export enum StreamerRiotAccountsError {
  InternalError = 'INTERNAL_ERROR',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND'
}

export type StreamerRiotAccountsPayload = {
  data: Maybe<StreamerRiotAccountsData>;
  error: Maybe<StreamerRiotAccountsError>;
};

export enum StreamerSearchError {
  InternalError = 'INTERNAL_ERROR',
  /** Query should be at least 2 characters long and no more than 50 characters long. */
  InvalidQuery = 'INVALID_QUERY'
}

export type StreamerSearchInput = {
  /** Query should be at least 2 characters long and no more than 50 characters long. */
  query: Scalars['String']['input'];
};

export type StreamerSearchPayload = {
  data: Maybe<Array<Streamer>>;
  error: Maybe<StreamerSearchError>;
};

export type StreamerUpdateWeb3WalletData = {
  streamer: StreamerPostMutationPayload;
};

export enum StreamerUpdateWeb3WalletError {
  InternalError = 'INTERNAL_ERROR',
  InvalidInput = 'INVALID_INPUT',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type StreamerUpdateWeb3WalletInput = {
  networkId: Scalars['ID']['input'];
  walletAddress: Scalars['String']['input'];
};

export type StreamerUpdateWeb3WalletPayload = {
  data: Maybe<StreamerUpdateWeb3WalletData>;
  error: Maybe<StreamerUpdateWeb3WalletError>;
  result: Scalars['Boolean']['output'];
};

export enum StreamerWalletsError {
  InternalError = 'INTERNAL_ERROR',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type StreamerWeb3Wallet = {
  address: Scalars['String']['output'];
  createdAt: Scalars['TimestampStrUtcMilli']['output'];
  network: Web3Network;
  updatedAt: Scalars['TimestampStrUtcMilli']['output'];
};

export type StreamerWeb3WalletsData = {
  wallets: Array<StreamerWeb3Wallet>;
};

export type StreamerWeb3WalletsPayload = {
  data: Maybe<StreamerWeb3WalletsData>;
  error: Maybe<StreamerWalletsError>;
};

export type StreamerWithdrawHistoryData = {
  items: Array<StreamerWithdrawHistoryItem>;
};

export enum StreamerWithdrawHistoryError {
  InternalError = 'INTERNAL_ERROR',
  InvalidPaginationCursor = 'INVALID_PAGINATION_CURSOR',
  InvalidPaginationDirection = 'INVALID_PAGINATION_DIRECTION',
  InvalidPaginationLimit = 'INVALID_PAGINATION_LIMIT',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type StreamerWithdrawHistoryItem = {
  createdAt: Scalars['TimestampStrUtcMilli']['output'];
  id: Scalars['ID']['output'];
  status: StreamerWithdrawalStatus;
  updatedAt: Scalars['TimestampStrUtcMilli']['output'];
  value: CurrencyValue;
};

export type StreamerWithdrawHistoryPayload = {
  data: Maybe<StreamerWithdrawHistoryData>;
  error: Maybe<StreamerWithdrawHistoryError>;
  pageInfo: Maybe<PageInfo>;
};

export type StreamerWithdrawal = {
  status: StreamerWithdrawalStatus;
  value: CurrencyValue;
};

export enum StreamerWithdrawalStatus {
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

export type StreamersFeaturedData = {
  streamers: Array<Streamer>;
};

export enum StreamersFeaturedError {
  InternalError = 'INTERNAL_ERROR'
}

export type StreamersFeaturedPayload = {
  data: Maybe<StreamersFeaturedData>;
  error: Maybe<StreamersFeaturedError>;
};

export type StreamingPlatform = {
  platform: Maybe<StreamingPlatformInfo>;
  streamer: Maybe<StreamingPlatformStreamer>;
};

export type StreamingPlatformInfo = {
  iconUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type StreamingPlatformStreamer = {
  channelDescription: Maybe<Scalars['String']['output']>;
  channelPreviewImgUrl: Maybe<Scalars['String']['output']>;
  channelUrl: Scalars['String']['output'];
  iconUrl: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isOnline: Maybe<Scalars['Boolean']['output']>;
  lastOnlineAt: Maybe<Scalars['TimestampStrUtcMilli']['output']>;
  name: Scalars['String']['output'];
  viewersCount: Maybe<Scalars['Int']['output']>;
};

export enum StreamingPlatformsError {
  InternalError = 'INTERNAL_ERROR'
}

export type StreamingPlatformsPayload = {
  data: Maybe<Array<StreamingPlatform>>;
  error: Maybe<StreamingPlatformsError>;
};

export type User = {
  email: Maybe<Scalars['String']['output']>;
  emailVerified: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  marketingSettings: UserMarketingSettingsPayload;
  name: Maybe<Scalars['String']['output']>;
  streamer: Maybe<Streamer>;
};

export enum UserError {
  InternalError = 'INTERNAL_ERROR',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type UserMarketingSettingsData = {
  isNewsletterAllowed: Scalars['Boolean']['output'];
};

export enum UserMarketingSettingsError {
  InternalError = 'INTERNAL_ERROR',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type UserMarketingSettingsPayload = {
  data: Maybe<UserMarketingSettingsData>;
  error: Maybe<UserMarketingSettingsError>;
};

export type UserMarketingSettingsUpdateData = {
  user: UserPostMutationPayload;
};

export enum UserMarketingSettingsUpdateError {
  InternalError = 'INTERNAL_ERROR',
  InvalidInput = 'INVALID_INPUT',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type UserMarketingSettingsUpdateInput = {
  isNewsletterAllowed: Scalars['Boolean']['input'];
};

export type UserMarketingSettingsUpdatePayload = {
  data: Maybe<UserMarketingSettingsUpdateData>;
  error: Maybe<UserMarketingSettingsUpdateError>;
  result: Scalars['Boolean']['output'];
};

export type UserPayload = {
  data: Maybe<User>;
  error: Maybe<UserError>;
};

export enum UserPostMutationError {
  InternalError = 'INTERNAL_ERROR'
}

export type UserPostMutationPayload = {
  data: Maybe<User>;
  error: Maybe<UserPostMutationError>;
};

export type Web3Address = {
  address: Scalars['String']['output'];
  currency: Web3Currency;
};

export type Web3Currency = {
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  maxWithdrawalAmount: Scalars['String']['output'];
  minWithdrawalAmount: Scalars['String']['output'];
  network: Web3Network;
};

export type Web3CurrencyValue = {
  currency: Web3Currency;
  value: Scalars['String']['output'];
};

export type Web3Network = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};
